@import "pixelato-aux";
@import "signin";
@import "offcanvas";


/* // Breakpoint

  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px

// mobile incluye tablet
@include media-breakpoint-between(xs, md) {}

//mobile sin tablet
@include media-breakpoint-between(xs, sm) {}

//escritorio
@include media-breakpoint-up(lg){}

@include media-breakpoint-only(xs) {}
@include media-breakpoint-only(sm) {}
@include media-breakpoint-only(md) {}
@include media-breakpoint-only(lg) {}
@include media-breakpoint-only(xl) {}


$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;


*/


/*  font-family: 'Montserrat', sans-serif; light:300; regular:400; medium: 500; semibold: 600; bold:700; extra-bold:800 */


body{
	font-family: 'Montserrat', sans-serif;
	font-weight:500;
}


.breadcrumb{
	@include titulo-interno14;
}
.pixelato-inner-title{
	
	@include titulo-interno18;
	font-weight:600;
	a{
		font-weight:600;
	}
}

.container-aux{
	padding:0rem 2rem;
}

.blog-main{
	.product-img{
		img{
			width:100%;
			height:auto;
			border:1px solid $border;
		}
	}
	.pixelato-list-product{
		.img-fluid{
			border:1px solid $border;
		}
	}
}
nav.navbar{
	.navbar-collapse{
		.navbar-nav{
			> li{
				text-transform:uppercase;
			}
		}
	}
}
aside{
	border-right:1px solid $border;
	h4{
		font-weight:$font-weight-light;
		@include titulo-interno25;
		
	}
	@include media-breakpoint-between(xs, sm) {
		border-right:none !important;
		padding-bottom:1rem;
	}
}


.pixelato-range{
	
	.slider-range{
		.ui-slider-horizontal{
			height:2px !important;
			border-radius:0rem !important;
			background:$border !important;
			border:none !important;
		}
		.ui-slider-handle{
			border-radius:100% !important;
			top: -.5em !important; 
			background:#000 !important;
			border:1px solid #000 !important;
			&.ui-state-active{
				background:#000 !important;
				border:1px solid #000 !important;
			}
		}
		.ui-slider-range{
			border-radius:0rem !important;
			background:#000 !important;
		}
	}
	.range-price{
		height:37px;
		margin-top:1rem;
		padding:0.5rem 0rem;
	}
}


.catagories-menu {
	> ul{
		list-style:none;
		> li {
			a{
				color:$primary !important;
			}
		}
		ul{
			list-style:none;
			padding-left:1rem;
			li{
				list-style:none;
				a{
					color:$primary !important;
				}
			}
		}
	}
	ul{
		li{
			a{
				color:$primary;
				display:block;
				&:hover{
					text-decoration:none;
				}
			}
		}	
	}
}

.catagories-menu-sofi {
	> ul{
		list-style:none;
		> li {
			a{
				color:$primary !important;
			}
		}
		ul{
			list-style:none;
			padding-left:1rem;
			li{
				list-style:none;
				a{
					color:$primary !important;
				}
			}
		}
	}
	ul{
		li{
			a{
				color:$primary;
				display:block;
				&:hover{
					text-decoration:none;
				}
			}
		}	
	}
}

.flex-column, .catagories-menu{
	a, ul li a{
		padding:0.5rem 1rem;
		@include titulo-interno14;
		&:hover{
			color:$gris !important;
		}
	}
}


.pixelato-color-select{
	.dropdown-menu{
		padding:0rem 0.8rem;
		li, a{
			text-transform:lowercase;
			@include titulo-interno14; 
		}
	}
}


.pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: $border-radius; 
  margin:0;
  li{
  		&:first-child a, &:first-child span {
		  margin-left: 0;
		  border-top-left-radius: $border-radius;
		  border-bottom-left-radius: $border-radius; 
		}
		&:last-child a, &:last-child span {
		  border-top-right-radius: $border-radius;
		  border-bottom-right-radius: $border-radius; 
		}
		&.active a, &.active span {
		  z-index: 1;
		  color: #fff;
		  background-color:$primary;
		  border-color: $primary; 
		}
		&.disabled a, &.disabled span {
		  color: #6c757d;
		  pointer-events: none;
		  cursor: auto;
		  background-color: #fff;
		  border-color: #dee2e6; 
		}
		 a, > span {
			  position: relative;
			  display: block;
			  padding: 0.5rem 0.75rem;
			  margin-left: -1px;
			  line-height: 1.25;
			  color: $primary;
			  background-color: #fff;
			  border: 1px solid #dee2e6; 
			  &:hover{
			  	z-index: 2;
			    color: #0056b3;
			    text-decoration: none;
			    background-color: #e9ecef;
			    border-color: #dee2e6;
			  }
			  &:focus{
			  	z-index: 2;
			    outline: 0;
			    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			  }
			}
  	}
}

.single-product-area{
	.single_product_desc{
		h2{
			text-transform:capitalize; 
		}
	}
}

.quantity{
	position:relative;
	.qty-text{
		appearance:textfield;
		-moz-appearance:textfield;
		-webkit-appearance:textfield;
	}
	.qty-minus{
		position:absolute;
		right:1rem;
		bottom:0;
	}
	.qty-plus{
		position:absolute;
		right:1rem;
		top:0;
		
	}
}

section{
	&.bottom{
	padding:2rem 0;
	border-top:8px solid #b3b3b3;
		ul{
			margin-left:0rem;
			li{
				list-style:none;
				display:inline-block;
				a{
					height:54px;
					width:54px;
					background-color:#313131;
					background-repeat:no-repeat;
					background-position:50% 50%;
					display:inline-block;
					border-radius:100%;
					margin:0rem 0.5rem 0.5rem 0rem;
					span{
						display:none;
						
					}
				}
				&.gmap{
					a{
						background-image:url(../img/pie-gmap.svg);
					}
				}
				&.waze{
					a{
						background-image:url(../img/pie-waze.svg);
					}
				}
				&.fb{
					a{
						background-image:url(../img/pie-fb.svg);
					}
				}
				&.ig{
					a{
						background-image:url(../img/pie-ig.svg);
					}
				}
			}
		}
	}
}

h1{
	font-weight:$font-weight-bold;
	@include titulo-interno37;
}

.product-price{
	@include titulo-interno40;
	font-weight:$font-weight-normal;
	color:$secondary;
}

.product-description{
	.product-price{
		@include titulo-interno18;
	}
}
.mia-search{
	width:250px;
}

.mia-precio-codigo-list{
	@include titulo-interno1;
}

.px-alerta{
	
	
	
	margin-top:-0.5rem;
	font-size: 1rem !important;
	letter-spacing: 0.03rem;
}

.no-mobile{
	@include no-mobile;

}
.solo-mobile{
	@include solo-mobile;
	
}



.master-lateral-colapser{
	text-align:right;
	#master-lateral-colapser{
		cursor:pointer;
		i{
			font-size:1.4rem;
		}
	}
}


@include media-breakpoint-between(xs, sm) {
	.aside-aux {
		display:none;
		&.open{
			display:block;
		}
	}
}

.catagories-menu {
	> ul{
		> li{
			display:none;
		}
	}
}

.px-tit-contanier{
	i{
		float:right;
		margin-top:0.25rem;
		@include solo-mobile;
		color:#333132;
		
	}
	.px-indicator{
		.fas.fa-minus-circle{
			display:none;
		}
		&.open{
			.fas.fa-minus-circle{
				display:inline-block;

			}
			.fas.fa-plus-circle{
				display:none;	
			}
		}
	}
	@include media-breakpoint-between(xs, sm){
		cursor:pointer;
	}
}
@include media-breakpoint-between(xs, sm){
	.filtro-menu{
		display:none;
		&.open{
			display:block;
		}
	}
	#px-modelo{
		display:none;
		&.open{
			display:block;
		}
	}
	#px-filtro-precio{
		display:none;
		&.open{
			display:block;
		}
	}
	#buscador_articulos_ajax{ 
		display:none;
		&.open{
			display:block;
		}
	}
}
.owl-item{
	.px-outter-aux{
		position:relative;
	}
	.px-inner-aux{
		position:absolute;
		bottom:30px;
		left:0;
		background:rgba(0,0,0,0.8);
	}
}

.px-home-mina-aux{
	margin:-1.5rem;
}
#article_search_text{
	height:47px;
}

#btn-show-kits{
	line-height:37px;
}

.ico-svg-primary{
	svg{
		path, rect{
			fill:$primary;
		}
	}
}

.ico-svg-secondary{
	svg{
		path, rect{
			fill:$secondary;
		}
	}
}



.ico-svg-white{
	svg{
		path, rect{
			fill:$white;
		}
	}
}

.ico-svg-black{
	svg{
		path, rect{
			fill:$black;
		}
	}
}

.px-position-relative{
	position:relative;
}

.px-link-absolute{
	&:before{
		position:absolute;
		height:100%;
		content:"";
		display:block;
		width:100%;
		top:0;
		left:0; 
	}
}

#featured_products_slider{
	position:relative;
	.owl-stage-outer{
		padding-top:3rem;
	}
	.owl-dots{
		position:absolute;
		top:0;
		right:0;
		
	}
}

#main_slider{
	position:relative;
	.owl-stage-outer{
		
	}
	.owl-dots{
		position:absolute;
		top:0;
		right:0;
		padding:0.3rem 0 0 0;
		.owl-dot{
			padding-left:0rem !important;
			padding-right:0.5rem !important;
		}
	}
}

#featured_collection_slider{
	position:relative;
	.owl-stage-outer{
		
	}
	.owl-dots{
		position:absolute;
		top:0;
		right:0;
		padding:0.3rem 0 0 0;
		.owl-dot{
			padding-left:0rem !important;
			padding-right:0.5rem !important;
		}
	}
}

.owl-carousel{
	.owl-dots{
		text-align:right;
		.owl-dot{
			padding-left:0.5rem !important;
			span{
				display:inline-block;
				height:11px;
				width:11px;
				background-color:#808080;
				border-radius:100%;
			}
			&.active{
				span{
					background-color:$secondary;
				}
			}
		}
		
	}		
}
#article_search_text{
	&:focus{
		border:none !important;
		box-shadow:none !important;
	}
}
.px-link-owl{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%; 
}

.btn-modelos{
	color: #009649;
	font-weight: $font-weight-bold;
    text-transform: uppercase;
    border-color: #009649;
	transition-duration: 0.4s;

}
.btn-modelos :hover{
	  background-color: #009649;
  	color: white;
}

	
